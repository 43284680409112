import { createAction, props } from '@ngrx/store';

import { Bus, ExpansionModule, ModbusClient, ProtocolType } from '@priva/next-model';

export const resetState = createAction('[Connectivity] Reset to init state');

export const updateModbusClient = createAction(
    '[Connectivity] Update modbus client communication settings',
    props<{ busNumber: Bus; modbusClient: ModbusClient }>(),
);

export const loadExpansionModules = createAction(
    '[Connectivity] Load expansion modules',
    props<{ expansionModules: ExpansionModule[] }>(),
);

export const updateExpansionModules = createAction(
    '[Connectivity] Update expansion modules',
    props<{
        expansionModuleUpdate: ExpansionModule;
        expansionModules: ExpansionModule[];
        isSelected: boolean;
    }>(),
);

export const updateExpansionModulesSuccess = createAction(
    '[Connectivity] Update expansion modules (success)',
    props<{ updatedExpansionModules: ExpansionModule[] }>(),
);

export const updateExpansionModuleProtocol = createAction(
    '[Connectivity] Update expansion module protocol',
    props<{ protocol: ProtocolType; busNumber: number }>(),
);

export const updateExpansionModuleProtocolSuccess = createAction(
    '[Connectivity] Update expansion module protocol (success)',
    props<{ updatedExpansionModules: ExpansionModule[] }>(),
);

export const saveControllerProtocolSettings = createAction(
    '[Connectivity] Save changed controller communication settings',
    props<{ systemComponentId: string }>(),
);
