import { createAction, props } from '@ngrx/store';

import { SearchResultGroup } from '../search-result.model';

export const getSearchResults = createAction(
    '[App API] Get search results',
    props<{ solutionId: string; searchTerms: string[]; searchFilter?: string }>(),
);
export const getSearchResultsSuccess = createAction(
    '[App API] Get search results (success)',
    props<{ searchResultGroups: SearchResultGroup[]; searchTerms: string[] }>(),
);
export const resetSearchResults = createAction('[App] Reset search state');
