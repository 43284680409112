import { ControllerProtocolSettings } from '@priva/next-model';

import { SystemComponentState } from 'app/template/system-component/state';

export interface ConnectivityStateContainer {
    systemComponent: SystemComponentState;
    connectivity: ConnectivityState;
}

export interface ConnectivityState {
    controllerProtocolSettings: ControllerProtocolSettings;
    isChanged: boolean;
}

export const initialState: ConnectivityState = {
    controllerProtocolSettings: undefined,
    isChanged: false,
};
