import { createAction, props } from '@ngrx/store';

import { ControllerProtocolSettings, SystemComponent } from '@priva/next-model';

export const getControllerProtocolSettings = createAction(
    '[Connectivity API] Get controller protocol settings',
    props<{ systemComponent: SystemComponent }>(),
);

export const getControllerProtocolSettingsSuccess = createAction(
    '[Connectivity API] Get controller protocol settings (success)',
    props<{ controllerProtocolSettings: ControllerProtocolSettings }>(),
);

export const applyControllerProtocolSettings = createAction(
    '[Connectivity API] Apply controller protocol settings',
    props<{ systemComponentId: string; controllerProtocolSettings: ControllerProtocolSettings }>(),
);

export const applyControllerProtocolSettingsSuccess = createAction(
    '[Connectivity API] Apply controller protocol settings (success)',
    props<{ controllerProtocolSettings: ControllerProtocolSettings }>(),
);
